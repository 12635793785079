import React, {Component} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import {Checkbox, Grid} from "@mui/material";
import {Button, CircularProgress, TextField, Typography} from "@material-ui/core";
import Select from "react-select";
import {findBidders} from "../../../services/LocationService";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import SelectableCard from "../../uiLibrary/SelectableCard";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import BidderMap from "./BidderMap";
import {APP_TYPE_KEY, DIALOG_TYPE_INFO, GERMANY_CENTER_COORDINATES, LAST_OPERATION_ID} from "../../../util/Constants";
import BidderSummary from "./BidderSummary";
import {Link} from "react-router-dom";
import {texisionBlue} from "../../../util/ColorTheme";
import {putAsyncCatch} from "../../../services/BackendService";

class PublishBidderSelection extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            zipCode: "",
            distanceKilometers: 10,
            searchTerm: "",
            pageNumber: 0,
            pageSize: 5,
            results: [],
            maxPageNumber: 0,
            totalResultCount: 0,
            showSelectedBidders: false,
            anchorEl: null,
            showMap: false,
            showReportBidderDialog: false,
            supportMessage: ""
        }
    }

    sendBidderReport = async() => {
        const operationId = localStorage.getItem(LAST_OPERATION_ID);
        let customerId = this.context.currentUser.id;
        let requestInfo = this.props.intl.formatMessage({id: "bidderSelection.missingBidder.request.info"});
        const supportMessageVo = {
            operationId,
            customerId,
            message: requestInfo + "\n\n" + this.state.supportMessage,
            appType: localStorage.getItem(APP_TYPE_KEY)
        };
        await putAsyncCatch(this.context, "/support/mail", supportMessageVo, this.props);
        this.setState({supportMessage: "", showReportBidderDialog: false});
    }

    cancelBidderReport = () => {
        this.setState({supportMessage: "", showReportBidderDialog: false});
    }

    handleZipcodeChange = (e) => {
        const newZipcode = e.target.value;
        const zipcodeRegex = /^[0-9]*$/;
        if (newZipcode === "" || zipcodeRegex.test(newZipcode)) {
            this.setState({zipCode: newZipcode});
        }
    }

    search = async(pageNumber) => {
        this.setState({loadingResults: true, noResults: false});
        const bidderSearchVo = {
            searchTerm: this.state.searchTerm,
            zipCode: this.state.zipCode,
            distanceKilometers: this.state.distanceKilometers,
            pageNumber,
            pageSize: this.state.pageSize
        }
        const result = await findBidders(this.context, this.props, bidderSearchVo);
        if (pageNumber === 0 && !result?.results?.length) {
            return this.setState({noResults: true, loadingResults: false});
        }
        if (pageNumber === 0 && result) {
            const maxPageNumber = result.totalResultCount && result.pageSize ? (Math.ceil(result.totalResultCount / result.pageSize) - 1) : 0;
            this.setState({maxPageNumber, totalResultCount: result.totalResultCount});
        }
        let results = this.state.results;
        // override result if this pageNumber result already exists
        if (results.find(r => r.pageNumber === result.pageNumber)) {
           results = results.filter(r => r.pageNumber !== result.pageNumber);
        }
        results.push(result);
        this.setState({results, loadingResults: false});
    }

    openPage = async(pageNumber) => {
        await this.setState({pageNumber});
        if (!this.state.results.find(r => r.pageNumber === pageNumber)) {
            await this.search(this.state.pageNumber);
        }
    }

    searchComponent = () => {
        return (
            <>
                {this.searchSetting("bidderSelection.zipcode.title", 200,
                    <TextField
                        disabled={this.props.isLoading}
                        value={this.state.zipCode}
                        inputProps={{maxLength: 5}}
                        label={this.props.intl.formatMessage({id: "bidderSelection.zipcode.placeholder"})}
                        variant="filled"
                        onChange={this.handleZipcodeChange}/>)}

                <br/>

                {this.searchSetting("bidderSelection.radius.title", 200,
                    <Select
                        styles={{menu: provided => ({ ...provided, zIndex: 9999})}} // Fixes the overlapping problem of the component
                        id="bidder-radius-id"
                        disabled={this.props.isLoading}
                        value={{
                            value: this.state.distanceKilometers,
                            label: this.state.distanceKilometers === 1500
                                ? this.props.intl.formatMessage({id: "bidderSelection.radius.all"}) : (this.state.distanceKilometers + " km")
                        }}
                        onChange={(e) => this.setState({distanceKilometers: e.value})}
                        options={[10, 25, 50, 100, 200, 500, 1500].map(number => {
                            return {
                                value: number,
                                label: number === 1500 ? this.props.intl.formatMessage({id: "bidderSelection.radius.all"}) : (number + " km")
                            }
                        })}/>)}

                <br/>

                {this.searchSetting("bidderSelection.company.title", 300,
                    <TextField
                        value={this.state.searchTerm}
                        inputProps={{maxLength: 128}}
                        label={this.props.intl.formatMessage({id: "bidderSelection.company.placeholder"})}
                        variant="filled"
                        style={{width: 300}}
                        disabled={this.props.isLoading}
                        onChange={(e) => this.setState({searchTerm: e.target.value})}/>)}

                <br/>

                {this.searchSetting("bidderSelection.pageSize.title", 200,
                    <Select
                        styles={{menu: provided => ({ ...provided, zIndex: 9999})}} // Fixes the overlapping problem of the component
                        id="bidder-page-size-id"
                        value={{value: this.state.pageSize, label: this.state.pageSize}}
                        disabled={this.props.isLoading}
                        onChange={(e) => this.setState({pageSize: e.value})}
                        options={[5, 10, 20, 50].map(number => {return {value: number, label: number}})}/>)}

                <br/>

                <Button
                    color="primary"
                    variant="contained"
                    onClick={async() => {
                        await this.setState({pageNumber: 0, results: []});
                        await this.search(0);
                    }}
                    disabled={(this.state.searchTerm.length < 3 && this.state.zipCode.length !== 5) || this.props.isLoading || this.state.loadingResults}>
                    <FormattedMessage id="commons.search"/>
                </Button>
            </>
        );
    }

    searchSetting = (titleId, componentWidth, component) => {
        return (
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <FormattedMessage id={titleId}/>
                </Grid>
                <Grid item style={{width: componentWidth}}>
                    {component}
                </Grid>
            </Grid>
        );
    }

    confirmationComponent = () => {
        return (
            <>
                {this.props.changeConfirmPublishing &&
                    <Grid container alignItems={"center"} style={{marginTop: 15}}>
                        <Grid item>
                            <Checkbox
                                color="primary"
                                checked={this.props.confirmPublishing}
                                disabled={this.props.isLoading}
                                onClick={() => this.props.changeConfirmPublishing()}/>
                        </Grid>
                        <Grid item xs style={{marginLeft: 12}}>
                            <FormattedMessage id={this.props.isRepublishing ? "navBar.republish.hint" : "navBar.publish.hint"}
                                              values={{tender: this.props.projectName}}/>
                        </Grid>
                    </Grid>
                }

                <br/>

                <Popover
                    key="selected-bidders-popover"
                    style={{zIndex: 10000}}
                    open={this.state.showSelectedBidders}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.setState({showSelectedBidders: false, anchorEl: null})}
                    anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                    transformOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                    {this.props.selectedBidders.map(b => <div style={{margin: 20}}>
                        {b.company}
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => {
                                if (this.props.selectedBidders?.length === 1) {
                                    this.setState({showSelectedBidders: false, anchorEl: null});
                                }
                                this.props.onBidderSelect(false, b);
                            }}
                            style={{marginBottom: 3}}>
                            {this.props.intl.formatMessage({id: "commons.remove.button"})}
                        </Button>
                    </div>)}
                </Popover>

                <Button
                    variant="text"
                    color="primary"
                    disabled={!this.props.selectedBidders?.length || this.props.isLoading}
                    onClick={(e) => this.setState({showSelectedBidders: true, anchorEl: e.currentTarget})}>
                    <FormattedMessage id="bidderSelection.selectedBidders.button" values={{numberOfBidders: this.props.selectedBidders?.length ?? 0}}/>
                </Button>
            </>
        );
    }

    arrowButton = (IconType, disabled, pageNumberToOpen) => {
        return (
            <Grid item>
                <IconButton
                    disabled={disabled || this.props.isLoading}
                    onClick={() => this.openPage(pageNumberToOpen)}>
                    <IconType/>
                </IconButton>
            </Grid>
        )
    }

    bidderSummary = (userZipDistanceVo) => {
        return (
            <Grid key={"found-bidder-item-" + userZipDistanceVo.userVo.id} item xs={6}>
                <SelectableCard
                    selected={!!this.props.selectedBidders.find(s => s.id === userZipDistanceVo.userVo.id)}
                    onSelect={() => this.props.onBidderSelect(
                        !this.props.selectedBidders.find(s => s.id === userZipDistanceVo.userVo.id),
                        userZipDistanceVo.userVo
                    )}>
                    <BidderSummary userZipDistanceVo={userZipDistanceVo}/>
                </SelectableCard>
            </Grid>
        );
    }

    resultComponent = () => {
        return (
            <div style={{display: "flex", flexDirection: "column", overflow: "hidden"}}>

                {!!this.state.results?.length && <>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Grid container>
                                {this.arrowButton(KeyboardDoubleArrowLeftIcon, this.state.pageNumber === 0, 0)}
                                {this.arrowButton(KeyboardArrowLeftIcon, this.state.pageNumber === 0, this.state.pageNumber - 1)}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">
                                <FormattedMessage
                                    id="bidderSelection.result.title"
                                    values={{pageNumber: this.state.pageNumber + 1, maxPageNumber: this.state.maxPageNumber + 1}}/>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                {this.arrowButton(KeyboardArrowRightIcon, this.state.pageNumber >= this.state.maxPageNumber, this.state.pageNumber + 1)}
                                {this.arrowButton(KeyboardDoubleArrowRightIcon, this.state.pageNumber >= this.state.maxPageNumber, this.state.maxPageNumber)}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" justifyContent="center" spacing={2}>
                        <Grid item>
                            <Typography variant="textSecondary">
                                <FormattedMessage
                                    id="bidderSelection.totelResultCount.title"
                                    values={{totalResultCount: this.state.totalResultCount, min: 1, max: 5}}/>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={this.props.isLoading}
                                onClick={() => this.setState({showMap: true})}>
                                <FormattedMessage id="bidderSelection.map.button"/>
                            </Button>
                        </Grid>
                    </Grid>

                </>}

                {this.state.noResults && <Typography variant="body2">
                    <FormattedMessage id="bidderSelection.noResults"/>
                    <br/><br/>
                    <FormattedMessage id="bidderSelection.noResults.hint"/>
                    <br/><br/>
                    <Link
                        onClick={() => this.setState({showReportBidderDialog: true})}
                        style={{color: texisionBlue}}>
                        <FormattedMessage id="bidderSelection.missingBidder.link"/>
                    </Link>
                </Typography>}

                <div style={{overflowY: this.state.loadingResults ? "hidden" : "auto", maxHeight: "42vh", marginTop: 20}}>

                    {this.state.loadingResults && <CircularProgress/>}

                    {!this.state.noResults && !this.state.loadingResults && <Grid container key="found-bidder-grid">
                        {this.state.results.find(r => r.pageNumber === this.state.pageNumber)
                            ?.results?.map(userZipDistanceVo => this.bidderSummary(userZipDistanceVo))}
                    </Grid>}

                </div>

            </div>
        );
    }

    render() {
        let defaultMapZoom = 1.2;
        let mapCenteringCoordinates = GERMANY_CENTER_COORDINATES;
        const pageResults = this.state.results.find(r => r.pageNumber === this.state.pageNumber)?.results;
        if (pageResults?.length) {
            const sourceCoordinates = pageResults[0]?.zipDistanceVo?.sourceCoordinates;
            if (sourceCoordinates?.longitude && sourceCoordinates?.latitude) {
                defaultMapZoom = 2;
                mapCenteringCoordinates.latitude = sourceCoordinates.latitude;
                mapCenteringCoordinates.longitude = sourceCoordinates.longitude;
            }
        }
        return (
            <>
                <TexisionDialog
                    open={this.state.showMap}
                    type={DIALOG_TYPE_INFO}
                    size="lg"
                    titleId="bidderSelection.map.dialog.title"
                    subtitleId="bidderSelection.map.dialog.subtitle"
                    content={<BidderMap
                        defaultZoom={defaultMapZoom}
                        sourceLongitude={mapCenteringCoordinates.longitude}
                        sourceLatitude={mapCenteringCoordinates.latitude}
                        userZipDistances={this.state.results.find(r => r.pageNumber === this.state.pageNumber)?.results}
                        onBidderSelect={(select, bidder) => this.props.onBidderSelect(select, bidder)}
                        selectedBidders={this.props.selectedBidders}/>
                    }
                    onAction={() => this.setState({showMap: false})}
                    actionId="commons.apply.button"/>

                <TexisionDialog
                    open={this.state.showReportBidderDialog}
                    type={DIALOG_TYPE_INFO}
                    size="lg"
                    titleId="bidderSelection.missingBidder.dialog.title"
                    subtitleId="bidderSelection.missingBidder.dialog.subtitle"
                    content={
                        <>
                            <TextField
                                id="requestText"
                                multiline={true}
                                minRows={7}
                                variant="outlined"
                                value={this.state.supportMessage}
                                style={{marginTop: "50px", width: "100%", height: "450px", whiteSpace: "pre-wrap"}}
                                onChange={(event) => this.setState({supportMessage: event.target.value})}
                            />
                        </>
                    }
                    cancelId="commons.cancel.button"
                    onCancel={() => this.cancelBidderReport()}
                    onAction={() => this.sendBidderReport()}
                    actionId="support.mail.send.button"/>

                <Grid container style={{marginTop: 30}} spacing={2}>

                    <Grid item xs={6}>

                        {this.searchComponent()}

                        <br/>
                        <br/>

                        {this.confirmationComponent()}

                    </Grid>

                    <Grid item xs={6} style={{textAlign: "center"}}>

                        {this.resultComponent()}

                    </Grid>

                </Grid>
            </>
        );
    }
}

export default injectIntl(withSnackbar(PublishBidderSelection));
