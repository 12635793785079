import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatPrice} from "../../../../util/Util";
import {getCockpitStatisticsCategoryLabel} from "../../../../util/Constants";
import {accumulateData, getLegendLabel, getNodeColor} from "../../../../services/StatisticsService";
import {InvoiceStatisticsTooltip} from "./InvoiceStatisticsTooltip";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {InvoiceStatisticsBarData} from "./InvoiceStatisticsBarData";
import {getActiveOperation} from "../../../../services/OperationService";

class InvoiceStatisticsBarChart extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            activeInvoiceId: null,
            activeKey: null
        }
    }

    onBarDataSelect = (key, invoiceId) => {
        this.props.onItemSelected(key, invoiceId);
        this.setState({activeKey: key, activeInvoiceId: invoiceId});
    }

    render() {
        const {
            sortedInvoices, statisticItemMap, selectedNodes, selectedTab, selectedView, getXAxisTickLabel
        } = this.props;

        const { activeInvoiceId, activeKey } = this.state;

        let chartData;

        const [data, dataKeys] = InvoiceStatisticsBarData({
            context: this.context, sortedInvoices, statisticItemMap, selectedNodes, selectedTab, selectedView
        });

        if (selectedView === "monthly.accumulated") {
            chartData = accumulateData(data, dataKeys, "invoiceId");
        } else {
            chartData = data;
        }

        let labelX = selectedView === "yearly"
            ? this.props.intl.formatMessage({id: "cockpit.statistics.year"})
            : this.props.intl.formatMessage({id: "cockpit.statistics.month"})

        return (
            <ResponsiveContainer debounce={300} width="100%" aspect={2.6} minWidth={900}>
                <BarChart
                    data={chartData}
                    margin={{top: 30, right: 50, bottom: 90}}>

                    <YAxis
                        scale={"auto"}
                        allowDecimals={selectedTab.includes("price")}
                        domain={[0, "auto"]}
                        tickFormatter={(value) => selectedTab.includes("price") ? formatPrice(value) : value.toFixed(0)}
                        padding={{top: 20}}
                        width={160}>
                        <Label
                            value={this.props.intl.formatMessage({id: "priceSheet.column." + selectedTab})
                                + " in " + getActiveOperation(this.context).activeProject.currency}
                            angle={-90}/>
                    </YAxis>

                    <XAxis
                        dataKey="invoiceId"
                        dy={10}
                        padding={{left: 20, right: 20}}
                        tickFormatter={(invoiceId) => getXAxisTickLabel(invoiceId)}>
                        <Label
                            value={labelX}
                            offset={30}
                            position='bottom'/>
                    </XAxis>

                    <CartesianGrid stroke="#f5f5f5"/>

                    <Tooltip
                        content={({active, payload, label}) =>
                            activeKey && activeInvoiceId !== null && activeInvoiceId !== undefined

                                ? <InvoiceStatisticsTooltip
                                    active={active}
                                    activeKey={activeKey}
                                    payload={payload}
                                    label={label}
                                    getXAxisTickLabel={getXAxisTickLabel}
                                    selectedTab={selectedTab}
                                    invoices={sortedInvoices}
                                    selectedNodes={selectedNodes}/>

                                : <div/>}/>

                    <Legend
                        align="center"
                        formatter={(value) => getLegendLabel(this.context, selectedNodes, value)}
                        payloadUniqBy={(payload) => payload.dataKey?.includes("pred") ? null : payload.dataKey}
                        wrapperStyle={{position: "relative", paddingTop: 20}}/>

                    {[...dataKeys].map(key => (
                        <Bar
                            key={key + "-bar"}
                            fill={getNodeColor(selectedNodes, key)}
                            onMouseEnter={(entry) => this.setState({activeKey: key, activeInvoiceId: entry.invoiceId})}
                            onMouseLeave={() => this.setState({activeKey: null, activeInvoiceId: null})}
                            onClick={(entry) => this.onBarDataSelect(key, entry.invoiceId)}
                            name={getCockpitStatisticsCategoryLabel(this.props.intl, key)}
                            dataKey={key}
                        />
                    ))}

                </BarChart>
            </ResponsiveContainer>
        );
    }
}

export default injectIntl(InvoiceStatisticsBarChart);
