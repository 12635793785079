import { Line } from "recharts";
import {getDataPoint, getLegendLabel, getStatisticItemsByInvoice} from "../../../../services/StatisticsService";
import {white} from "../../../../util/ColorTheme";

export const InvoiceStatisticsLineData = ({
    context,
    activeInvoiceId,
    activeKey,
    focusedDataKey,
    sortedInvoices,
    onHideTooltip,
    onItemSelected,
    onShowTooltip,
    statisticItemMap,
    selectedNodes,
    selectedInvoiceId,
    selectedInvoiceItem,
    selectedTab,
    selectedView
}) => {

    const customDot = ({cx, cy, key, payload, color}) => {
        const isSelected = selectedInvoiceItem
            && selectedInvoiceId !== undefined
            && selectedInvoiceId !== null
            && [selectedInvoiceItem?.articleNumber, selectedInvoiceItem?.articleName, selectedInvoiceItem?.alternateDescription].includes(key)
            && payload.invoiceId === selectedInvoiceId;
        return (
            <circle
                key={key + cx + cy}
                cx={cx}
                cy={cy}
                r={isSelected ? 8 : 4}
                stroke={color}
                strokeWidth={3}
                fill={white}/>
        );
    }

    const customActiveDot = ({cx, cy, key, payload, color}) => {
        const isActive = key === activeKey && payload.invoiceId === activeInvoiceId;
        return (
            <circle
                cx={cx}
                cy={cy}
                r={8}
                stroke={isActive ? color : "transparent"}
                strokeWidth={3}
                fill={isActive ? white : "transparent"}
                onMouseOver={(_) => onShowTooltip(key, payload.invoiceId)}
                onMouseLeave={(_) => onHideTooltip()}
                onClick={() => onItemSelected(key, payload.invoiceId)}/>
        );
    }

    const getLine = (invoiceId, statisticItem) => {
        const key = statisticItem.key;
        let color = statisticItem.color;
        let name = getLegendLabel(context, selectedNodes, key);

        return (
            <Line
                key={key + invoiceId}
                hide={focusedDataKey && key !== focusedDataKey}
                connectNulls={true}
                type="linear"
                name={name}
                dataKey={key}
                strokeWidth={3}
                stroke={color}
                yAxisId={selectedTab}
                dot={({cx, cy, payload}) => customDot({cx, cy, payload, key, color})}
                activeDot={({cx, cy, payload}) => customActiveDot({cx, cy, payload, key, color})}
            />
        );
    }

    let data = [];
    let lines = [];

    if (statisticItemMap.length === 0 || selectedNodes.length === 0 || !sortedInvoices || sortedInvoices.length === 0) {
        return [null, null];
    }

    if (selectedView === "yearly") {
        let yearMap = new Map();
        let allItems = new Map();
        sortedInvoices.forEach(invoice => {
            let year = (new Date(invoice.invoiceDate)).getFullYear();
            let dataPoints = yearMap.has(year) ? yearMap.get(year) : {invoiceId: year}
            const statisticItems = getStatisticItemsByInvoice(invoice.id, selectedNodes, statisticItemMap);
            statisticItems.forEach(item => {
                const existing = dataPoints[item.key];
                const current = getDataPoint(item, selectedTab);
                dataPoints[item.key] = !existing ? current : existing + current;
                allItems.set(item.key, item);
            });
            yearMap.set(year, dataPoints);
        });
        yearMap.forEach(function(dataPoint, year) {
            allItems.values().forEach(function(item) {
                lines.push(getLine(year, item));
            });
            data.push(dataPoint);
        });
    } else {
        sortedInvoices.forEach(invoice => {
            const dataPoints = {invoiceId: invoice.id};
            const statisticItems = getStatisticItemsByInvoice(invoice.id, selectedNodes, statisticItemMap);

            statisticItems.forEach(item => {
                dataPoints[item.key] = getDataPoint(item, selectedTab);
                lines.push(getLine(invoice.id, item));
            })

            data.push(dataPoints);
        });
    }
    return [data, lines];
}
