import React, {Component} from "react";
import FooterWrapper from "../FooterWrapper";
import PropTypes from "prop-types";
import {withSnackbar} from "notistack";
import {injectIntl} from "react-intl";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {contractBlockText, getContract, isContractBlockIncomplete, updateContract} from "../../../../services/ContractService";
import {convertStringToNumber} from "../../../../util/Util";
import {Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, Icon, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContractBlock from "../../../documents/contract/ContractBlock";
import {CHAPTER_TYPES} from "../../../../util/Constants";

class ContractPage extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            contract: null,
            isLoading: false
        }
    }

    async componentDidMount() {
        this.setState({contract: await getContract(this.context, this.props, this.props.projectId)});
    }

    onContinue = async() => {
        const contract = JSON.parse(JSON.stringify(this.state.contract));
        contract.contractDurationMonths = convertStringToNumber(contract.contractDurationMonths);
        contract.contractRenewalMonths = convertStringToNumber(contract.contractRenewalMonths);
        contract.contractTerminationMonths = convertStringToNumber(contract.contractTerminationMonths);
        contract.paymentTermDays = convertStringToNumber(contract.paymentTermDays);
        if (contract.contractChapter) {
            delete contract.contractChapter;
        }
        if (contract.assortmentCategories) {
            delete contract.assortmentCategories;
        }
        this.setState({isLoading: true});
        const updatedContract = await updateContract(this.context, this.props, contract, this.props.projectId, true);
        this.setState({contract: updatedContract, isLoading: false});
        this.props.onContinue(updatedContract);
    }

    isValid = () => {
        const contract = this.state.contract;
        return contract?.startOfServiceDate && contract?.startOfServiceDate > 0
            && contract?.contractDurationMonths && contract?.contractDurationMonths > 0
            && contract?.contractRenewalMonths && contract?.contractRenewalMonths > 0
            && contract?.contractTerminationMonths && contract?.contractTerminationMonths > 0
            && contract?.paymentTermDays && contract?.paymentTermDays > 0;
    }

    render() {
        return (
            <FooterWrapper
                invalidData={!this.isValid()}
                onContinue={() => this.onContinue()}
                onCancel={this.props.onCancel}
                onBack={this.props.onBack}
                isLoading={this.state.isLoading}>
                <Card>
                    <CardContent>
                            {this.state.contract?.contractChapter?.sort((a, b) => a.index - b.index).map(chapter => {
                                if ([CHAPTER_TYPES.EXECUTION_TIME, CHAPTER_TYPES.ACCOUNTING,  CHAPTER_TYPES.TERMINATION].includes(chapter.chapterType)) {
                                    return (
                                        <Accordion key={chapter.id} elevation={1}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls={chapter.headline + "-CONTENT"}
                                                id={chapter.headline + "-HEADER"}>
                                                <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
                                                    <Grid item xs>
                                                        <Typography variant="h6">
                                                            {chapter.headline}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <div style={{color: "orange"}}>
                                                            {isContractBlockIncomplete(this.state.contract, chapter.index)
                                                                ? this.props.intl.formatMessage({id: "contract.chapter.incomplete.hint"}) : ""}
                                                        </div>
                                                    </Grid>
                                                    <Grid item>
                                                        <Icon style={{
                                                            paddingLeft: "10px",
                                                            color: isContractBlockIncomplete(this.state.contract, chapter.index) ? "orange" : "grey"
                                                        }}>edit
                                                        </Icon>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{width:"100%"}}>
                                                    <ContractBlock
                                                        readOnly={false}
                                                        contract={this.state.contract}
                                                        onContractChange={(contract) => this.setState({contract})}
                                                        chapterType={chapter.chapterType}/>
                                                    {contractBlockText(this.state.contract, chapter, this.props.intl)}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </CardContent>
                    </Card>
            </FooterWrapper>
        );
    }
}

ContractPage.propTypes = {
    onBack: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired
};

export default withSnackbar(injectIntl(ContractPage));
