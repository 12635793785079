import React, {Component} from 'react';
import {Grid, Select} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import {FormattedMessage, injectIntl} from "react-intl";

class ChartTypeSelection extends Component {

    render() {
        return (
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={2}>
                    <Select
                        native
                        value={this.props.chartType}
                        label={this.props.intl.formatMessage({id: "cockpit.statistics." + this.props.chartType + "Chart"})}
                        onChange={(e) => this.props.onChartTypeChange(e.target.value)}
                        inputProps={{name: 'net-gross-select', id: 'nett-gross-native-simple'}}>
                        <option
                            key={"line-chart-option"}
                            value={"line"}>
                            {this.props.intl.formatMessage({id: "cockpit.statistics.lineChart"})}
                        </option>
                        <option
                            key={"bar-chart-option"}
                            value={"bar"}>
                            {this.props.intl.formatMessage({id: "cockpit.statistics.barChart"})}
                        </option>
                        <option
                            key={"table-option"}
                            value={"table"}>
                            {this.props.intl.formatMessage({id: "cockpit.statistics.table"})}
                        </option>
                    </Select>
                </Grid>

                <Grid item>
                    <Select
                        native
                        value={this.props.view}
                        label={this.props.intl.formatMessage({id: "cockpit.statistics." + this.props.view})}
                        onChange={(e) => this.props.onViewChange(e.target.value)}
                        inputProps={{name: 'net-gross-select', id: 'nett-gross-native-simple'}}>
                        <option
                            key={"montly-option"}
                            value={"monthly"}>
                            {this.props.intl.formatMessage({id: "cockpit.statistics.monthly"})}
                        </option>
                        <option
                            key={"montly-accumulated-option"}
                            value={"monthly.accumulated"}>
                            {this.props.intl.formatMessage({id: "cockpit.statistics.monthly.accumulated"})}
                        </option>
                        <option
                            key={"yearly-option"}
                            value={"yearly"}>
                            {this.props.intl.formatMessage({id: "cockpit.statistics.yearly"})}
                        </option>
                    </Select>
               </Grid>

                {this.props.chartType === "table" &&
                <Grid item >
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                            <Checkbox
                                color="primary"
                                checked={this.props.heatMap}
                                onChange={(e) => this.props.onHeatMapChange(e.target.checked)}/>
                        </Grid>
                        <Grid item>
                            <FormattedMessage id="cockpit.statistics.heatMap"/>
                        </Grid>
                    </Grid>
                </Grid>}
            </Grid>
        );
    }
}

export default injectIntl(ChartTypeSelection);
