import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import {
    LOGISTIC_TYPE_DECENTRAL, LOGISTIC_TYPE_CENTRAL,
    EQUIPMENT_TYPE_ASSORTMENT,
    LOGISTIC_TYPE_SINGLE,
    DIALOG_TYPE_ERROR,
    DIALOG_TYPE_WARNING,
    DIALOG_TYPE_INFO, APP_TYPE_KEY, COCKPIT_APP, TENDER_APP, ORDER_APP, OFFER_APP
} from './Constants';

// PNG
import {texisionGreen, texisionRed, white} from './ColorTheme';

export function isCockpit() {
    return localStorage.getItem(APP_TYPE_KEY) === COCKPIT_APP;
}

export function isOffer() {
    return localStorage.getItem(APP_TYPE_KEY) === OFFER_APP;
}

export function isOrder() {
    return localStorage.getItem(APP_TYPE_KEY) === ORDER_APP;
}

export function isTender() {
    return localStorage.getItem(APP_TYPE_KEY) === TENDER_APP;
}

// functions to determine if a string is empty or blank

export function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

export function isEmpty(str) {
    return (!str || 0 === str.length);
}

export function isNotEmptyOrBlank(str) {
    return !(isEmpty(str) || isBlank(str));
}

export function isEmptyOrBlank(str) {
    return (isEmpty(str) || isBlank(str));
}

export function convertStringToNumber(string) {
    if (!string) return 0;
    let parsed = parseInt(string);
    if (typeof parsed === "number" && !isNaN(parsed)) {
        return parsed;
    } else {
        return 0;
    }
}

export function sortAsc(a, b) {

    // Umlaute will be sorted next to origin letter. ä after a...
    a = replaceUmlauts(a);
    b = replaceUmlauts(b)

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }

    return 0;
}

export function sortArticles(a, b) {

    let aCategory = replaceUmlauts(a.category);
    let aSubCategory = replaceUmlauts(a.subcategory);
    let aArticleCategory = replaceUmlauts(a.articleCategory);

    let bCategory = replaceUmlauts(b.category);
    let bSubcategory = replaceUmlauts(b.subcategory);
    let bArticleCategory = replaceUmlauts(b.articleCategory);

    // sort by main category
    if (aCategory < bCategory) {
        return -1;
    }

    if (aCategory > bCategory) {
        return 1;
    }

    // sort by sub category
    if (aSubCategory < bSubcategory) {
        return -1;
    }

    if (aSubCategory > bSubcategory) {
        return 1;
    }

    // sort by article category
    if (aArticleCategory < bArticleCategory) {
        return -1;
    }

    if (aArticleCategory > bArticleCategory) {
        return 1;
    }
}

export function sortOperatingResource(a, b) {

    let aName = replaceUmlauts(a.name);
    let bName = replaceUmlauts(b.name);

    // 1. Criteria: Sort by name
    if (aName < bName) {
        return -1;
    }

    if (aName > bName) {
        return 1;
    }

    // 2. Criteria: Sort by ID
    if (a.id < b.id) {
        return -1;
    }

    if (a.id > b.id) {
        return 1;
    }
}

function replaceUmlauts(value) {
    value = value.toLowerCase();
    value = value.replace(/ä/g,"a");
    value = value.replace(/ö/g,"o");
    value = value.replace(/ü/g,"u");
    value = value.replace(/ß/g,"s");
    return value;
}

export function createAssortmentSource(assortmentId) {
    return ({'sourceId': assortmentId, 'type': EQUIPMENT_TYPE_ASSORTMENT });
}

export function createErrorMessage(message, props) {
    let id = props.enqueueSnackbar(message, {variant: 'error', anchorOrigin: {vertical: 'top', horizontal: 'center'}, action: (
        <IconButton style={{marginLeft: "auto"}} onClick={() => props.closeSnackbar(id)}>
            <CloseIcon />
        </IconButton>
    )});
}

export function createWarnMessage(message, props) {
    let id = props.enqueueSnackbar(message, {variant: 'warning', anchorOrigin: {vertical: 'top', horizontal: 'center'}, action: (
            <IconButton style={{marginLeft: "auto"}} onClick={() => props.closeSnackbar(id)}>
                <CloseIcon />
            </IconButton>
        )});
}

export function createSuccessMessage(message, props) {
    let id = props.enqueueSnackbar(message, {persist: false, variant: 'success', anchorOrigin: {vertical: 'top', horizontal: 'right'}, action: (
        <IconButton style={{marginLeft: "auto"}} onClick={() => props.closeSnackbar(id)}>
            <CloseIcon />
        </IconButton>
    )});
}

export function isInCentralMode(selectedUnit) {
    return (selectedUnit.deliveryType === LOGISTIC_TYPE_CENTRAL && selectedUnit.pickupType === LOGISTIC_TYPE_CENTRAL)
        || (selectedUnit.deliveryType === LOGISTIC_TYPE_SINGLE && selectedUnit.pickupType === LOGISTIC_TYPE_SINGLE);
}

export function isInDecentralMode(selectedUnit) {
    return selectedUnit.deliveryType === LOGISTIC_TYPE_DECENTRAL && selectedUnit.pickupType === LOGISTIC_TYPE_DECENTRAL;
}

export function enhanceAssignedResourcesWithMasterData(operatingResources, assignedResources) {

    if (!operatingResources?.length || !assignedResources?.length) {
        return [];
    }

    let resourcesToReturn = [];

    for (let i = 0; i < assignedResources.length; i++) {
        let assignedResource = assignedResources[i];

        for (let j = 0; j < operatingResources.length; j++) {
            let resourcesMasterData = operatingResources[j];

            if (assignedResource.operatingResourceId === resourcesMasterData.id) {

                let resource = {
                    id: resourcesMasterData.id,
                    unitResourceId: assignedResource.id,
                    version: assignedResource.version,
                    name: resourcesMasterData.name,
                    description: resourcesMasterData.description,
                    category: resourcesMasterData.category,
                    subCategory: resourcesMasterData.subCategory,
                    features: resourcesMasterData.features,
                    unitId: assignedResource.businessUnitId,
                    count: assignedResource.count,
                    resourceOffsetting: assignedResource.resourceOffsetting,
                    imageVos: resourcesMasterData.imageVos
                }

                resourcesToReturn.push(resource)
            }
        }
    }

    return resourcesToReturn;
}

export function validateAllFeaturesHaveValues(features) {
    // false means everything is fine -> feature has value
    if (features && features.length > 0) {
        for (let i = 0; i < features.length; i++) {

            if (!features[i]) {
                continue;
            }

            if (isEmptyOrBlank(features[i].value)) {
                return true;
            }
        }
    }
    return false;
}

export function getDialogIcon(type, color) {
    let iconColor = color ?? white;
    switch (type) {
        case DIALOG_TYPE_ERROR:
            return <ErrorOutlineIcon style={{color: iconColor, paddingTop: "10px"}}/>;
        case DIALOG_TYPE_WARNING:
            return <ReportProblemOutlinedIcon style={{color: iconColor, paddingTop: "10px"}}/>;
        case DIALOG_TYPE_INFO:
            return <InfoOutlinedIcon style={{color: iconColor, paddingTop: "10px"}}/>;
        default:
            return <div/>;
    }
}

export function getAddresses(project, that) {
    if (!project.businessUnits?.units || project.businessUnits.units.length === 0) {
        return that.props.intl.formatMessage({id: "project.addresses.notAvailable"});
    } else {
        const units = project.businessUnits.units;
        let addresses = [];
        units.forEach((unit) => {
            if (unit.city) {
                addresses.push(unit.city);
            }
        });
        if (addresses.length === 0) {
            return that.props.intl.formatMessage({id: "project.addresses.notAvailable"});
        } else {
            let text = "";
            addresses = [...new Set(addresses)].sort();
            for (let i = 0; i < addresses.length; i++) {
                text += addresses[i];
                if (i < addresses.length - 1) {
                    text += ", ";
                }
            }
            return text;
        }
    }
}

export function formatPrice(price) {
    if (!price && price !== 0) {
        return "-";
    }
    return price.toLocaleString("en-GB", {minimumFractionDigits: 2, maximumFractionDigits: 2}).toString()
        .replaceAll(".", "_").replaceAll(",", ".").replaceAll("_", ",");
}

export function formatAmount(amount) {
    // If the number is an integer, simply return it as it is.
    if (Number.isInteger(amount)) {
        return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }
    // Convert number to a string with 2 decimal points.
    let numStr = amount.toFixed(2);
    // Replace the dot with a comma for the decimal point.
    numStr = numStr.replace(".", ",");
    // Replace the comma for the thousands separator with a dot.
    numStr = numStr.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return numStr;
}

export function formatPriceDifference(round, priceA, priceB) {
    if (round === 1 || !priceA || priceA === priceB) {
        return formatPrice(priceB);
    } else {
        const difference = priceB - priceA;
        const color = difference < 0 ? texisionGreen : texisionRed;
        const prefix = difference > 0 ? "+" : "";
        return (
            <>
                {formatPrice(priceB)}
                <div style={{marginLeft: 10, color: color}}>
                    {"(" + prefix + formatPrice(difference) + ")"}
                </div>
            </>
        );
    }
}

export const address = (intl, offer) => {
    if (!offer?.user) {
        return "";
    }
    const user = offer.user;
    let city = "";
    if (user.zipCode) {
        city += user.zipCode;
    }
    if (user.zipCode && user.city) {
        city += " ";
    }
    if (user.city) {
        city += user.city;
    }
    let country = "";
    if (user.country) {
        country += intl.formatMessage({id: "constants.country." + user.country.toLowerCase()});
    }
    let text = user.streetAndNumber;
    if (user.streetAndNumber && city) {
        text += ", " + city;
        if (country) {
            text += ", " + country;
        }
    } else if (user.streetAndNumber && country) {
        text += ", " + country;
    }
    return text;
}

export function featuresText(features){
    if (!features?.length) {
        return "";
    }
    let text = "";
    for (let i = 0; i < features.length; i++) {
        text += features[i].value;
        if (i < features.length - 1) {
            text += ", ";
        }
    }
    return text;
}

export function shortenString(string, length = 35) {
    if (string.length > length) {
        return string.substring(0, length - 3) + " ...";
    } else {
        return string;
    }
}