import {getDataPoint, getStatisticItemsByInvoice} from "../../../../services/StatisticsService";

export const InvoiceStatisticsBarData = ({
    sortedInvoices,
    statisticItemMap,
    selectedNodes,
    selectedTab,
    selectedView
 }) => {

    let sumMap = new Map();
    let data = [];
    let dataKeys = new Set();

    if (selectedNodes.length === 0 || !sortedInvoices || sortedInvoices.length === 0) {
        return [null, null];
    }

    if (selectedView === "yearly") {
        let yearMap = new Map();
        sortedInvoices.forEach(invoice => {
            let year = (new Date(invoice.invoiceDate)).getFullYear();
            let dataPoints = yearMap.has(year) ? yearMap.get(year) : {invoiceId: year}
            const statisticItems = getStatisticItemsByInvoice(invoice.id, selectedNodes, statisticItemMap);
            statisticItems.forEach(item => {
                const existingPoint = dataPoints[item.key];
                const existingSum = sumMap.has(item.key) ? sumMap.get(item.key) : 0;
                const current = getDataPoint(item, selectedTab);
                dataPoints[item.key] = !existingPoint ? current : existingPoint + current;
                sumMap.set(item.key, existingSum + current);
                dataKeys.add(item.key);
            });
            yearMap.set(year, dataPoints);
        });
        data = Array.from(yearMap.values());
    } else {
        sortedInvoices.forEach(invoice => {
            const dataPoints = {invoiceId: invoice.id};
            const statisticItems = getStatisticItemsByInvoice(invoice.id, selectedNodes, statisticItemMap);

            statisticItems.forEach(item => {
                const existingSum = sumMap.has(item.key) ? sumMap.get(item.key) : 0;
                const current = getDataPoint(item, selectedTab);
                dataPoints[item.key] = current;
                sumMap.set(item.key, existingSum + current);
                dataKeys.add(item.key);
            })

            data.push(dataPoints);
        });
    }
    return [data, dataKeys, sumMap];
}
