import {FormControl, Grid, InputLabel, Select, TextField, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import ProfilePanel from "../administration/profile/ProfilePanel";
import React from "react";
import PropTypes from "prop-types";
import {isTender} from "../../util/Util";
import {INDUSTRY_TYPES} from "../../util/Constants";

class OperationPanel extends React.Component {

    render() {
        const isEditing = this.props.projectId !== null && this.props.projectId !== undefined;
        const isTender = this.props.isTender;
        let title;
        if (isTender && !isEditing) {
            title = "tender.dialog.title.create";
        } else if (isTender && isEditing) {
            title = "tender.dialog.title.edit";
        } else if (!isTender && !isEditing) {
            title = "project.dialog.title.create";
        } else {
            title = "project.dialog.title.edit";
        }
        const nameTitle = isTender ? "tender.dialog.text.name" : "project.dialog.text.name";
        const nameTextFieldTitle = isTender ? "tender.create.field.name" : "project.create.field.name";
        const nameExampleTitle = isTender ? "tender.dialog.text.name.helperText" : "project.dialog.text.name.helperText";
        const profileTitle = isTender ? "tender.dialog.text.profiles" : "project.dialog.text.profiles";
        return (
            <Grid container spacing={1}>

                {!this.props.hideTitle && <Grid item xs={12} style={{paddingBottom: "20px"}}>
                    <Typography variant="h2">
                        <FormattedMessage id={title}/>
                    </Typography>
                </Grid>}

                <Grid item xs={12}>
                    <Typography variant="h5">
                        <FormattedMessage id={nameTitle}/>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required id="projectName"
                        value={this.props.projectName}
                        label={this.props.intl.formatMessage({id: nameTextFieldTitle})}
                        helperText={this.props.intl.formatMessage({id: nameExampleTitle})}
                        variant="filled"
                        inputProps={{maxLength: 300}}
                        style={{width: "100%"}}
                        onChange={(e) => this.props.onNameChange(e.target.value)}/>
                </Grid>

                <Grid item xs={12}/>

                <Grid item xs={12}>
                    <Typography variant="h5" style={{marginTop: "1rem"}}>
                        <FormattedMessage id="project.dialog.text.industry"/>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <FormControl required variant="outlined" style={{width: "100%"}}>

                        <InputLabel id="is_private-native-label" variant="filled">
                            <FormattedMessage id="project.create.field.industry"/>
                        </InputLabel>

                        <Select
                            labelId="subcategory-native-simple-label"
                            native
                            variant="filled"
                            value={this.props.industryType}
                            onChange={(e) => this.props.onIndustryTypeChange(e.target.value)}
                            label={this.props.intl.formatMessage({id: "project.create.field.industry"})}>
                            <option
                                key={"undefined"}
                                value={INDUSTRY_TYPES.UNDEFINED}>
                                {this.props.intl.formatMessage({id: "constants.IndustryType.UNDEFINED"})}
                            </option>
                            <option
                                key={"health"}
                                value={INDUSTRY_TYPES.HEALTH}>
                                {this.props.intl.formatMessage({id: "constants.IndustryType.HEALTH"})}
                            </option>
                            <option
                                key={"hotel"}
                                value={INDUSTRY_TYPES.HOTEL}>
                                {this.props.intl.formatMessage({id: "constants.IndustryType.HOTEL"})}
                            </option>
                        </Select>
                    </FormControl>
                </Grid>


                {this.props.showProcurement && <>
                    <Grid item xs={12}>
                        <Typography variant="h5" style={{marginTop: "1rem"}}>
                        <FormattedMessage id="project.dialog.text.procurement"/>
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl required variant="outlined" style={{width: "100%"}}>

                            <InputLabel id="is_private-native-label" variant="filled">
                                <FormattedMessage id="project.create.field.procurement"/>
                            </InputLabel>

                            <Select
                                labelId="subcategory-native-simple-label"
                                native
                                variant="filled"
                                value={this.props.isPrivate}
                                onChange={(e) => this.props.onIsPrivateChange(e.target.value)}
                                label={this.props.intl.formatMessage({id: "project.create.field.procurement"})}>
                                <option
                                    key={"private"}
                                    value={true}>
                                    {this.props.intl.formatMessage({id: "constants.ProjectProcurement.PRIVATE"})}
                                </option>
                                <option
                                    key={"public"}
                                    value={false}
                                    disabled>
                                    {this.props.intl.formatMessage({id: "constants.ProjectProcurement.PUBLIC"})}
                                </option>
                            </Select>
                        </FormControl>
                    </Grid>
                </>}

                {this.props.showProfile &&
                    <>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                style={{marginTop: "1rem"}}>
                                <FormattedMessage id={profileTitle}/>
                            </Typography>
                        </Grid>

                        <ProfilePanel
                            profileIds={this.props.profileIds}
                            onChange={(profileIds) =>  this.props.onProfileIdsChange(profileIds)}/>
                    </>
                }
            </Grid>
        );
    }
}

OperationPanel.defaultProps = {
    isTender: isTender(),
    projectId: null,
    projectName: "",
    isPrivate: true,
    profileIds: [],
    hideProfile: false
}

OperationPanel.propTypes = {
    isTender: PropTypes.bool.isRequired,
    projectId: PropTypes.number.isRequired,
    projectName: PropTypes.string.isRequired,
    onNameChange: PropTypes.func.isRequired,
    isPrivate: PropTypes.bool.isRequired,
    onIsPrivateChange: PropTypes.func.isRequired,
    profileIds: PropTypes.array.isRequired,
    onProfileIdsChange: PropTypes.func.isRequired,
    industryType: PropTypes.string.isRequired,
    onIndustryTypeChange: PropTypes.func.isRequired,
    hideTitle: PropTypes.bool.isRequired,
    hideProfile: PropTypes.bool.isRequired
};

export default injectIntl(OperationPanel);
