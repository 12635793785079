import React, {Component} from 'react';
import {InvoiceStatisticsLineData} from "./InvoiceStatisticsLineData";
import {CartesianGrid, Label, Legend, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatPrice} from "../../../../util/Util";
import {InvoiceStatisticsTooltip} from "./InvoiceStatisticsTooltip";
import {injectIntl} from "react-intl";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {accumulateData} from "../../../../services/StatisticsService";

class InvoiceStatisticsLineChart extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            focusedDataKey: null
        }
    }

    focusDataKey = (dataKey) => {
        if (this.state.focusedDataKey === dataKey) {
            this.setState({focusedDataKey: null});
        } else {
            this.setState({focusedDataKey: null});
        }
    }

    render() {
        const {
            activeInvoiceId, activeKey, sortedInvoices,
            onHideTooltip, onItemSelected, onShowTooltip, statisticItemMap, selectedNodes,
            selectedInvoiceId, selectedInvoiceItem, selectedTab, selectedView, getXAxisTickLabel
        } = this.props;

        const [data, lines] = InvoiceStatisticsLineData({
            context: this.context, activeInvoiceId, activeKey, sortedInvoices,
            onHideTooltip, onItemSelected, onShowTooltip, statisticItemMap, selectedNodes,
            selectedInvoiceId, selectedInvoiceItem, selectedTab, selectedView, getXAxisTickLabel
        });

        let chartData;
        if (selectedView === "monthly.accumulated" && !!data?.length) {
            chartData = accumulateData(data, Array.from(Object.keys(data[0])), "invoiceId");
        } else {
            chartData = data;
        }

        let labelX = selectedView === "yearly"
            ? this.props.intl.formatMessage({id: "cockpit.statistics.year"})
            : this.props.intl.formatMessage({id: "cockpit.statistics.month"})

        return (
            <ResponsiveContainer debounce={300} width="100%" aspect={2.8} minWidth={900}>

                <LineChart
                    data={chartData}
                    margin={{top: 30, right: 50, bottom: 30}}>

                    <YAxis
                        allowDecimals={selectedTab.includes("price")}
                        domain={selectedTab.includes("price")
                            ? [() => 0, dataMax => Math.ceil(dataMax)]
                            : ["auto", "auto"]}
                        tickFormatter={(value) => selectedTab.includes("price") ? formatPrice(value) : value.toFixed(0)}
                        yAxisId={selectedTab}
                        width={120}>
                        <Label
                            value={this.props.intl.formatMessage({id: "priceSheet.column." + selectedTab})}
                            angle={-90}/>
                    </YAxis>

                    <XAxis
                        allowDuplicatedCategory={false}
                        dataKey="invoiceId"
                        dy={10}
                        tickFormatter={(invoiceId) => getXAxisTickLabel(invoiceId)}>
                        <Label
                            value={labelX}
                            offset={30}
                            position='bottom'/>
                    </XAxis>

                    <Tooltip
                        content={({active, payload, label}) =>
                            activeKey && activeInvoiceId !== null && activeInvoiceId !== undefined

                                ? <InvoiceStatisticsTooltip
                                    active={active}
                                    activeKey={activeKey}
                                    payload={payload}
                                    label={label}
                                    getXAxisTickLabel={getXAxisTickLabel}
                                    selectedTab={selectedTab}
                                    invoices={sortedInvoices}
                                    selectedNodes={selectedNodes}
                                />

                                : <div/>}
                    />

                    <CartesianGrid stroke="#f5f5f5"/>

                    <Legend
                        align="center"
                        onClick={(data) => this.focusDataKey(data.dataKey)}
                        payloadUniqBy={(payload) => payload.dataKey}
                        wrapperStyle={{position: "relative", paddingTop: 20}}/>

                    {lines}

                </LineChart>

            </ResponsiveContainer>
        );
    }
}

export default injectIntl(InvoiceStatisticsLineChart);
