import {FormattedMessage, injectIntl} from 'react-intl';
import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {getDialogColor} from '../../util/ColorTheme';
import {getDialogIcon} from '../../util/Util';
import {Divider, Typography} from '@material-ui/core';
import "../../css/Dialog.css";
import MediaQuery from "react-responsive";
import {LinearProgressIndicator} from "./LinearProgressIndicator";

const TexisionDialog = ({
    open, 
    type, 
    onAction, actionId, actionDisabled,
    onAction2, actionId2, actionDisabled2,
    onCancel, cancelId, cancelDisabled, 
    onEnter, onClose, onExit, 
    style, 
    size, 
    dialogTitle, hasNoTitle, titleId,
    isLoading,
    replacements, subtitleId,
    content}) => {

    let hasDividers = true;
    if ((!onAction || !actionId) && (!onCancel || !cancelId)) {
        hasDividers = false;
    }

    const actionButtons = (
        <>
            {onCancel && cancelId && <Grid item>

                <Button
                    onClick={onCancel}
                    color="inherit"
                    disabled={cancelDisabled ?? false}
                    style={{minWidth: "150px"}}>
                    <FormattedMessage id={cancelId}/>
                </Button>

            </Grid>}

            {onAction2 && actionId2 && <Grid item>

                <Button
                    onClick={onAction2}
                    color="secondary"
                    disabled={actionDisabled2 ?? false}
                    variant="contained"
                    style={{minWidth: "150px"}}>
                    <FormattedMessage id={actionId2}/>
                </Button>

            </Grid>}

            {onAction && actionId && <Grid item>

                <Button
                    onClick={onAction}
                    color="primary"
                    disabled={actionDisabled ?? false}
                    variant="contained"
                    style={{minWidth: "150px"}}>
                    <FormattedMessage id={actionId}/>
                </Button>

            </Grid>}
        </>
    );

    return <Dialog
        open={open}
        disableEnforceFocus={true}
        aria-labelledby="texision-dialog-title" 
        aria-describedby="texision-dialog-description"
        TransitionProps={{
            onEnter: onEnter ? onEnter : () => {}, 
            onClose: onClose ? onClose : () => {}, 
            onExit: onExit ? onExit : () => {}}
        }
        style={style ?? {zIndex: 1301}}
        fullWidth={true}
        maxWidth={size ?? "md"}>

        {dialogTitle ?? (!hasNoTitle &&
        <DialogTitle id="texision-dialog-title" style={{backgroundColor: getDialogColor(type)}}>
            <Grid container alignItems="center" alignContent="center" spacing={3} style={{paddingBottom: "8px", paddingTop: "8px", paddingLeft: "25px", paddingRight: "25px"}}>
                {type && <Grid item>
                    {getDialogIcon(type)}
                </Grid>}
                <Grid item>
                    <Typography variant="h4">
                        <FormattedMessage id={titleId ?? "commons.warning.label"} values={replacements} style={{fontSize: "18px"}}/>
                    </Typography>
                </Grid>
            </Grid>
        </DialogTitle>)}

        <DialogContent style={{paddingBottom: "40px", paddingTop: "40px", paddingLeft: "50px", paddingRight: "50px"}}>
            <DialogContentText id="alert-dialog-description" style={{whiteSpace: "pre-line"}}>
                {subtitleId && <FormattedMessage id={subtitleId} values={replacements}/>}
            </DialogContentText>
            {content}
        </DialogContent>

        {hasDividers && <Divider style={{marginLeft: "50px", marginRight: "50px"}}/>}

        <LinearProgressIndicator active={isLoading}/>

        {hasDividers && <DialogActions style={{paddingTop: "30px", paddingBottom: "40px", paddingLeft: "50px", paddingRight: "50px"}}>

            <MediaQuery minWidth={800}>
                <Grid container justifyContent='flex-end' spacing={2}>
                    {actionButtons}
                </Grid>
            </MediaQuery>

            <MediaQuery maxWidth={799}>
                <Grid container justifyContent='center' spacing={2}>
                    {actionButtons}
                </Grid>
            </MediaQuery>

        </DialogActions>}

    </Dialog>;
}

export default injectIntl(TexisionDialog);
