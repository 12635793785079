import React, {useRef} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({id, htmlText, onChange}) => {
    const quillRef = useRef(null);

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'align': [] }],
            [{ 'script': 'sub'}, { 'script': 'super'}]
        ],
    };

    return (
        <div>
            <ReactQuill
                id={id}
                ref={quillRef}
                value={htmlText}
                onChange={onChange}
                modules={modules}
                theme="snow"  // Snow ist das Standard-Theme
            />
        </div>
    );
};

export default RichTextEditor;
