import {deleteAsyncCatch, downloadUsingGet, getAsyncCatch, putAsyncCatch} from "./BackendService";
import fileDownload from "js-file-download";
import {CHAPTER_TYPES, LICENSE_TYPES} from "../util/Constants";
import {createErrorMessage, isTender} from "../util/Util";
import parse from "html-react-parser";
import {format} from "date-fns";
import {remapDay} from "../util/DateUtil";
import {updateValidationMap} from "./ProjectService";
import React from "react";

export async function getContract(context, props, projectId) {
    const currentProjectId = projectId !== null && projectId !== undefined ? projectId : context.appData.activeProjectId;
    const contract = await getAsyncCatch(context, "/contract/" + currentProjectId, props);
    return contract ? contract : null;
}

export async function updateContract(context, props, contract, projectId, hideSuccess) {
    const contractProjectId = projectId !== null && projectId !== undefined ? projectId : context.appData.activeProjectId;
    let errorTextMap = new Map();
    errorTextMap.set(400, "contractService.updateContract.400");
    errorTextMap.set(405, "contractService.updateContract.405");
    const updatedContract = await putAsyncCatch(context, "/contract/" + contractProjectId, contract, props, hideSuccess, errorTextMap);
    if (updatedContract) {
        context.setUnsavedChanges(false);
        await updateValidationMap(context);
    }
    return updatedContract ? updatedContract : null;
}

export async function downloadContract(context, props) {
    const response = await downloadUsingGet("/contract/export/" + context.appData.activeProjectId);
    if (response?.status === 200) {
        const fileName = response.headers['content-disposition'].split("filename=")[1];
        fileDownload(response.data, fileName);
    } else if ([401, 403].includes(response?.status)) {
        await context.logout();
    } else if ([402, 423].includes(response?.status)) {
        context.showNoLicenseDialog(LICENSE_TYPES.TENDER_LICENSE);
    } else {
        createErrorMessage(props.intl.formatMessage({id: "contract.export.error"}), props);
    }
}

export async function updateContractChapter(context, props, chapter) {
    const path = "/contract/" + chapter.projectId + "/chapter";
    const updatedChapter = await putAsyncCatch(context, path, chapter, props);
    return updatedChapter ? updatedChapter : null;
}

export async function resetContractChapter(context, props, chapter) {
    const path = "/contract/" + chapter.projectId + "/chapter/" + chapter.chapterType;
    const resetChapter = await deleteAsyncCatch(context, path, props);
    return resetChapter ? resetChapter : null;
}

export function isContractBlockIncomplete(contract, chapterType) {
    if (!contract) {
        return true;
    }
    return (chapterType === CHAPTER_TYPES.EXECUTION_TIME && (
            !contract.startOfServiceDate || contract.startOfServiceDate < 1
            || !contract.contractDurationMonths || contract.contractDurationMonths < 1
            || !contract.contractRenewalMonths || contract.contractRenewalMonths < 1))
        || (chapterType === CHAPTER_TYPES.GUIDELINES_CLIENT && isTender() && (
            !contract.processingDamageEuro || contract.processingDamageEuro < 1
            || !contract.personalDamageEuro || contract.personalDamageEuro < 1
            || !contract.propertyDamageEuro || contract.propertyDamageEuro < 1
            || !contract.keyLossEuro || contract.keyLossEuro < 1
            || (!contract.useXRechnung && !contract.useZugferd)))
        || (chapterType === CHAPTER_TYPES.ACCOUNTING && (!contract.paymentTermDays || contract.paymentTermDays < 1))
        || (chapterType === CHAPTER_TYPES.PRICE_HEDGING && isTender() && contract.hasPriceHedging && (!contract.priceHedgingTo || contract.priceHedgingTo < 1))
        || (chapterType === CHAPTER_TYPES.TERMINATION && (
            !contract.contractTerminationMonths || contract.contractTerminationMonths < 1
            || !contract.contractDurationMonths || contract.contractDurationMonths < 1
            || !contract.contractRenewalMonths || contract.contractRenewalMonths < 1));
}

export function placeholderListItem(placeholder, intl) {
    let description = intl.formatMessage({id: "contract.placeholder." + placeholder});
    return <span><li>{description} : <b>{"{"+placeholder+"}"}</b></li></span>;
}

export function contractPlaceholder(contract, chapter, intl) {
    let listItems = [];
    switch (chapter.chapterType) {
        case CHAPTER_TYPES.CONTRACT_SUBJECT:
            listItems.push(placeholderListItem("assortmentCategories", intl));
            break;
        case CHAPTER_TYPES.EXECUTION_TIME:
            listItems.push(placeholderListItem("startOfServiceDate", intl));
            listItems.push(placeholderListItem("contractDurationMonths", intl));
            listItems.push(placeholderListItem("contractRenewalMonths", intl));
            break;
        case CHAPTER_TYPES.GUIDELINES_CLIENT:
            listItems.push(placeholderListItem("propertyDamageEuro", intl));
            listItems.push(placeholderListItem("personalDamageEuro", intl));
            listItems.push(placeholderListItem("processingDamageEuro", intl));
            listItems.push(placeholderListItem("keyLossEuro", intl));
            listItems.push(placeholderListItem("currency", intl));
            break;
        case CHAPTER_TYPES.ACCOUNTING:
            listItems.push(placeholderListItem("paymentTermDays", intl));
            break;
        case CHAPTER_TYPES.PRICE_HEDGING:
            listItems.push(placeholderListItem("priceHedgingTo", intl));
            listItems.push(placeholderListItem("priceChangeClaimSpecific", intl));
            listItems.push(placeholderListItem("priceChangeClaimGeneral", intl));
            listItems.push(placeholderListItem("firstIndex", intl));
            listItems.push(placeholderListItem("changedIndex", intl));
            listItems.push(placeholderListItem("priceChangeEffectSpecific", intl));
            listItems.push(placeholderListItem("priceChangeEffectGeneral", intl));
            listItems.push(placeholderListItem("changedIndexMonths", intl));
            listItems.push(placeholderListItem("referenceYear1", intl));
            listItems.push(placeholderListItem("referenceYear2", intl));
            break;
        case CHAPTER_TYPES.TERMINATION:
            listItems.push(placeholderListItem("contractDurationMonths", intl));
            listItems.push(placeholderListItem("contractTerminationMonths", intl));
            listItems.push(placeholderListItem("contractRenewalMonths", intl));
            break;
        case CHAPTER_TYPES.OTHER_AGREEMENTS:
            listItems.push(placeholderListItem("otherAgreements", intl));
            break;
        default:
            return null;
    }

    return listItems.length > 0
        ?   <div>
                {intl.formatMessage({id: "contract.placeholder.introduction"})}
                {listItems.map(s => <div><ul>{s}</ul></div>)}
            </div>
        : <></>
}

export function contractBlockText(contract, chapter, intl, currency) {
    let text = chapter?.text;
    if (!contract || !text) return "";
    let priceChangeClaimDate;
    if (contract.priceHedgingTo) {
        const currentDate = new Date(contract.priceHedgingTo);
        const newMonth = currentDate.getMonth()-3;
        priceChangeClaimDate = new Date(currentDate.getFullYear(), newMonth, remapDay(newMonth, currentDate.getDate()));
    }
    switch (chapter.chapterType) {
        case CHAPTER_TYPES.CONTRACT_SUBJECT:
            text = text.replace(/{assortmentCategories}/g, categoriesAsText(contract.assortmentCategories, intl));
            break;
        case CHAPTER_TYPES.EXECUTION_TIME:
            text = text.replace(/{startOfServiceDate}/g, formatDate(contract.startOfServiceDate, "dd.MM.yyyy", intl))
                .replace(/{contractDurationMonths}/g, formatText(contract.contractDurationMonths, intl))
                .replace(/{contractRenewalMonths}/g, formatText(contract.contractRenewalMonths, intl));
            break;
        case CHAPTER_TYPES.GUIDELINES_CLIENT:
            text = text.replace(/{propertyDamageEuro}/g, formatText(contract.propertyDamageEuro, intl))
                .replace(/{personalDamageEuro}/g, formatText(contract.personalDamageEuro, intl))
                .replace(/{processingDamageEuro}/g, formatText(contract.processingDamageEuro, intl))
                .replace(/{keyLossEuro}/g, formatText(contract.keyLossEuro, intl))
                .replace(/{currency}/g, currency ?? "€")
            break;
        case CHAPTER_TYPES.ACCOUNTING:
            text = text.replace(/{paymentTermDays}/g, formatText(contract.paymentTermDays, intl))
            break;
        case CHAPTER_TYPES.PRICE_HEDGING:
            text = text.replace(/{priceHedgingTo}/g, formatDate(contract.priceHedgingTo, "dd.MM.yyyy", intl))
                .replace(/{priceChangeClaimSpecific}/g, getPriceChangeClaimSpecific(contract.priceHedgingTo, intl))
                .replace(/{priceChangeClaimGeneral}/g, getPriceChangeClaimGeneral(contract.priceHedgingTo, intl))
                .replace(/{firstIndex}/g, getFirstIndex(contract.startOfServiceDate, intl))
                .replace(/{changedIndex}/g, getIndex(priceChangeClaimDate, "MM.yyyy", intl))
                .replace(/{priceChangeEffectSpecific}/g, getPriceChangeEffectSpecific(contract.priceHedgingTo, intl))
                .replace(/{priceChangeEffectGeneral}/g, getPriceChangeEffectGeneral(contract.priceHedgingTo, intl))
                .replace(/{changedIndexMonths}/g, getIndex(priceChangeClaimDate, "MM", intl))
                .replace(/{referenceYear1}/g, getReferenceYear1(priceChangeClaimDate))
                .replace(/{referenceYear2}/g, getReferenceYear2(priceChangeClaimDate));
            break;
        case CHAPTER_TYPES.TERMINATION:
            text = text.replace(/{contractDurationMonths}/g, formatText(contract.contractDurationMonths, intl))
                .replace(/{contractTerminationMonths}/g, formatText(contract.contractTerminationMonths, intl))
                .replace(/{contractRenewalMonths}/g, formatText(contract.contractRenewalMonths, intl));
            break;
        case CHAPTER_TYPES.OTHER_AGREEMENTS:
            text = text.replace(/{otherAgreements}/g, contract.otherAgreements ?? "");
            break;
        default:
            break;
    }
    return parse(text);
}

export function contractSubChapterText(contract, subChapter, intl) {
    if (!contract || !subChapter || !intl) {
        return null;
    }
    let text = subChapter.text;
    switch (subChapter.chapterType) {
        case CHAPTER_TYPES.GUIDELINE_INVOICES:
            if (contract.useZugferd) {
                text = text.replace(/{invoiceType}/g, intl.formatMessage({id: "contract.invoiceType.zugferd"}));
            } else if (contract.useXRechnung) {
                text = text.replace(/{invoiceType}/g, intl.formatMessage({id: "contract.invoiceType.xrechnung"}));
            } else {
                return null;
            }
            break;
        case CHAPTER_TYPES.GUIDELINE_COCKPIT:
            if (!contract.useCockpit) return null;
            break;
        case CHAPTER_TYPES.GUIDELINE_ORDER:
            if (!contract.useOrder) return null;
            break;
        default:
            return null;
    }

    return (
        <>
            <h4>
                {subChapter.headline}
            </h4>
            {parse(text)}
        </>
    );
}

function categoriesAsText(categories, intl) {
    if (!categories?.length || (
            !categories.includes("RESIDENTS_LAUNDRY") && !categories.includes("STATIONARY_TEXTILES")
            && !categories.includes("WORK_WEAR") && !categories.includes("CUSTOM_ARTICLES"))) {
        return intl.formatMessage({id: "contract.noArticles.text"});
    } else {
        let text = "";
        let text1 = "";
        let categories1 = categories.filter(c => ["RESIDENTS_LAUNDRY", "STATIONARY_TEXTILES", "WORK_WEAR"].includes(c));
        if (categories1 && categories1.length > 0) {
            for (let i = 0; i < categories1.length; i++) {
                text1 += intl.formatMessage({id: "contract.assortmentCategory." + categories1[i].toLowerCase()});
                if (i !== categories1.length - 1) {
                    if (i === categories1.length - 2) {
                        text1 += " " + intl.formatMessage({id: "commons.and"}) + " ";
                    } else {
                        text1 += ", "
                    }
                }
            }
        }
        let hasText2 = categories.includes("CUSTOM_ARTICLES");
        if (text1) {
            text += " " + intl.formatMessage({id: "contract.assortmentCategory.text1"}) + " " + text1;
            if (hasText2) {
                text += ", ";
            }
        }
        if (hasText2) {
            if (text1) {
                text += intl.formatMessage({id: "commons.and"}) + " ";
            }
            text += intl.formatMessage({id: "contract.assortmentCategory.text2"});
        }
        return text;
    }
}

// if price change claim month is september to december, the reference year 1 for the index is the current year, otherwise the past year
function getReferenceYear1(dateInMillis) {
    if (!dateInMillis || new Date(dateInMillis).getMonth() >= 8) {
        return "aktuelles Jahr";
    } else {
        return "Vorjahr";
    }
}

// if price change claim month is september to december, the reference year 2 for the index is the previous year, otherwise the pre-previous year
function getReferenceYear2(dateInMillis) {
    if (!dateInMillis || new Date(dateInMillis).getMonth() >= 8) {
        return "Vorjahr";
    } else {
        return "Vor-Vorjahr";
    }
}

function getFirstIndex(milliseconds, intl) {
    let currentDate = new Date(milliseconds);
    let date;
    // december of last year, if start time is january to june
    if (currentDate.getMonth() >= 0 && currentDate.getMonth() <= 5) {
        date = new Date(currentDate.getFullYear()-1, 11, 1);
        // december of last year, if reference date is april to september,
    } else {
        date = new Date(currentDate.getFullYear(), 5, 1);
    }
    return formatDate(date, "MM.yyyy", intl);
}

function getIndex(milliseconds, dateFormat, intl) {
    let currentDate = new Date(milliseconds);
    let date;
    // june of last year, if reference date is january to march
    if (currentDate.getMonth() >= 0 && currentDate.getMonth() < 2) {
        date = new Date(currentDate.getFullYear()-1, 5, 1);
        // december of last year, if reference date is april to september,
    } else if (currentDate.getMonth() >= 2 && currentDate.getMonth() < 8) {
        date = new Date(currentDate.getFullYear()-1, 11, 1);
        // june of current year, if reference date is october to december
    } else {
        date = new Date(currentDate.getFullYear(), 5, 1);
    }
    return formatDate(date, dateFormat, intl);
}

function formatText(number, intl) {
    if (!number || number < 1) {
        return intl.formatMessage({id: "contract.text.inputRequired"});
    } else {
        return number;
    }
}

function formatDate(milliseconds, dateFormat, intl) {
    if (!milliseconds || milliseconds < 1) return intl.formatMessage({id: "contract.text.inputRequired"});
    return format(milliseconds, dateFormat);
}

function getPriceChangeClaimSpecific(priceHedgingTo, intl) {
    let currentDate = new Date(priceHedgingTo);
    let dateFormat = "dd.MM.yyyy";
    // 3 months before end of price hedging
    let newMonth = currentDate.getMonth()-3;
    let date = new Date(currentDate.getFullYear(), newMonth, remapDay(newMonth, currentDate.getDate()));
    return formatDate(date, dateFormat, intl);
}

function getPriceChangeClaimGeneral(priceHedgingTo, intl) {
    let currentDate = new Date(priceHedgingTo);
    let dateFormat = "dd.MM.";
    // 3 months before end of price hedging
    let newMonth = currentDate.getMonth()-3;
    let date = new Date(currentDate.getFullYear(), newMonth, remapDay(newMonth, currentDate.getDate()));
    return formatDate(date, dateFormat, intl);
}

function getPriceChangeEffectSpecific(priceHedgingTo, intl) {
    let currentDate = new Date(priceHedgingTo);
    let dateFormat = "dd.MM.yyyy";
    // first day after price hedging ends
    let date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()+1);
    return formatDate(date, dateFormat, intl);
}

function getPriceChangeEffectGeneral(priceHedgingTo, intl) {
    let currentDate = new Date(priceHedgingTo);
    let dateFormat = "dd.MM.";
    // first day after price hedging ends
    let date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()+1);
    return formatDate(date, dateFormat, intl);
}
